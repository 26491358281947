import React, { useEffect, useState } from 'react'
import { placasPendientes } from '../../api/tramiteApi';
import { Controller, useForm } from 'react-hook-form';
import { Watch } from '@mui/icons-material';
import { Select } from '@mui/material';

export const BandejaPlaca = () => {

    const [listado, setListado] = useState([])
    const [orden, setOrden] = useState({
        columna: 'sede', // Columna inicial para ordenar
        direccion: 'asc', // Dirección inicial del orden ('asc' o 'desc')
    });

    let fecha = new Date();
    fecha.setDate(fecha.getDate());
    let fechaActual = fecha.toISOString().substring(0, 10);

    fecha.setDate(fecha.getDate() - 20);

    let fechaAyer = fecha.toISOString().substring(0, 10);

    const handleOrdenar = (columna) => {
        const nuevaDireccion =
            orden.columna === columna && orden.direccion === 'asc' ? 'desc' : 'asc';
        ordenarDatos(columna, nuevaDireccion);
    };

    const ordenarDatos = (columna, direccion) => {
        const datosOrdenados = [...listado].sort((a, b) => {
            let valorA = a[columna];
            let valorB = b[columna];

            // Comprobando el tipo de valor
            if (typeof valorA === 'number' && typeof valorB === 'number') {
                return direccion === 'asc' ? valorA - valorB : valorB - valorA;
            } else if (typeof valorA === 'string' && typeof valorB === 'string') {
                return direccion === 'asc' ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
            } else if (valorA instanceof Date && valorB instanceof Date) {
                return direccion === 'asc' ? valorA - valorB : valorB - valorA;
            }

            // Comparación por defecto (asumiendo que son strings)
            return direccion === 'asc' ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
        });

        setListado(datosOrdenados);
        setOrden({ columna, direccion });
    };


    const handleCheckboxChange = (id) => {
        setListado((prevData) =>
            prevData.map((item) =>
                item.placa === id ? { ...item, check: !item.check } : item
            )
        );
    };



    useEffect(() => {
        const getData = async () => {
            try {
                console.log("Entro al get data ");
                const dataPost = { "sede": "1" }
                const respuesta = await placasPendientes(dataPost);
                console.log("respuesta ", respuesta);

                setListado(respuesta.lista)
            } catch (error) {
                console.log("error ", error);

            }

        }
        getData()

    }, [])


    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log("submit");
        console.log(data);
    };





    return (
        <div className="container-fluid p-0 m-0">

            <div className="container">
                <h4 className="text-center pb-3 pt-3 ">Bandeja de Entregas</h4>

                <div className="row m-0 pt-3 pb-3 border">


                    <div className="col-12 col-md-2">
                        <label className="form-label ">Desde</label>
                        <input
                            className="form-control form-control-sm"
                            type="date"
                            defaultValue={fechaAyer}
                            {...register("Desde")}
                        />
                    </div>
                    <div className="col-12 col-md-2">
                        <label className="form-label">Hasta</label>
                        <input
                            className="form-control form-control-sm"
                            type="date"
                            defaultValue={fechaActual}
                            {...register("Hasta")}
                        />
                    </div>

                    <div className="col-12 col-md-2">
                        <label className="form-label">Placa</label>
                        <input
                            type="text"
                            className="form-control form-control-sm px-1"
                            {...register("Tramite")}
                        />
                    </div>

                    <div className="col-12 col-md-4">
                        <label className="form-label">Procurador</label>
                        <input
                            type="text"
                            className="form-control form-control-sm px-1"
                            {...register("Cliente")}
                        />
                    </div>

                    <div className="col-md-2 d-flex justify-content-center align-items-end">
                        <button className="col-md-12 btn btn-sm btn-main"

                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>




            <div className="container pt-3">

                <button className='btn btn-main rounded-0 rounded-top '>Agregar </button>

                <table className='table  border table-striped table-responsive'>
                    <thead>
                        <tr>
                            <th style={{ width: "5rem" }}>
                                <input className="bold" style={{ width: "1.1rem", height: "1.1rem" }} type="checkbox" value="" id="" />
                                &nbsp; All

                            </th>
                            <th className='bold' onClick={() => handleOrdenar('placa')}>Fecha
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                            <th className='bold  ' onClick={() => handleOrdenar('sede')}>Encargado
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                            <th className='bold  ' onClick={() => handleOrdenar('estado')}>Estado
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listado.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="form-check">
                                        <input className="form-check-input" style={{ width: "1.3rem", height: "1.3rem" }} 
                                        type="checkbox" value="" id="defaultCheck1" checked={item.check} onChange={() => handleCheckboxChange(item.placa)} />
                                    </div>
                                </td>
                                <td>2024-10-05</td>
                                <td> Procurador 2</td>
                                <td> Terminado</td>
                            </tr>

                        ))}
                    </tbody>
                </table>



                <table className='table  border table-striped table-responsive'>
                    <thead>
                        <tr>
                            <th style={{ width: "5rem" }}>
                                <input className="bold" style={{ width: "1.1rem", height: "1.1rem" }} type="checkbox" value="" id="" />
                                &nbsp; All

                            </th>
                            <th className='bold' onClick={() => handleOrdenar('placa')}>Placa
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                            <th className='bold  ' onClick={() => handleOrdenar('sede')}>Sede
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                            <th className='bold  ' onClick={() => handleOrdenar('estado')}>Estado
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listado.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="form-check">
                                        <input className="form-check-input" style={{ width: "1.3rem", height: "1.3rem" }} 
                                        type="checkbox" value="" id="defaultCheck1" checked={item.check} onChange={() => handleCheckboxChange(item.placa)} />
                                    </div>
                                </td>
                                <td>{item.placa}</td>
                                <td> {item.sede}</td>
                                <td> Por Recoger</td>
                            </tr>

                        ))}


                    </tbody>
                </table>


            </div>









        </div>
    )
}
