export const ruta_login = '/inmatriculacion/login';
export const ruta_historial = '/inmatriculacion/inmatriculaciones';
export const ruta_tramite_agregar = '/inmatriculacion/tramite/agregar';
export const ruta_tramite_editar = '/inmatriculacion/tramite/editar';
export const ruta_tramite_pago_agregar = '/inmatriculacion/tramite/pago-agregar';
export const ruta_tramite_pago_editar = '/inmatriculacion/tramite/pago-editar';
export const ruta_tramite_confirmacion = '/inmatriculacion/tramite/confirmacion';
export const ruta_vehiculo = '/inmatriculacion/vehiculo';

export const ruta_personas = '/personas'
export const ruta_personas_agregar = '/personas/agregar'
export const ruta_personas_editar = '/personas/editar'

export const ruta_gestor_de_archivos = '/inmatriculacion/gestor-archivos'

export const ruta_reportes_inmatriculaciones = '/reporte/inmatriculaciones'
export const ruta_reportes_general = '/reporte/general'
export const ruta_reportes_dashboard = '/dashboard'

export const ruta_placa_entrega = '/placaentrega'
export const ruta_placa_entregas = '/placa/entrega'



export const ruta_sin_acceso = "/sin-acceso"





export const codigo_bandeja = "S0201000000"
export const codigo_tramite_agregar = "S0201010000"
export const codigo_tramite_editar = "S0201020000"
export const codigo_tramite_anular = "S0201030000"
export const codigo_gestor_de_archivos = "S0201040000"
export const codigo_enviar_iofe = "S0201050000"

export const codigo_personas = "S0202000000"
export const codigo_personas_agregar = "S0202010000"
export const codigo_personas_editar = "S0202020000"

export const codigo_reportes = "S0203000000"
export const codigo_reportes_inmatriculaciones = "S0203010000"
export const codigo_reportes_general = "S0203020000"
export const codigo_reportes_dashboard = "S0201000000"

export const codigo_externo_placaEntrega = "S0201000000"
export const codigo_placa_entrega = "S0205000000"

