import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import Swal from "sweetalert2";
import { TramiteMedioPago } from "./TramiteMedioPago";
import { TramiteCancelacion } from "./TramiteCancelacion";
import {
  tramiteCrear,
  tramiteMedioCancelacionesEditar,
} from "../../api/tramiteApi";
import Grid from "@mui/material/Grid";

import Alert from "@mui/material/Alert";
import {
  ruta_historial,
  ruta_tramite_confirmacion,
 
  ruta_vehiculo,
} from "../../constants/constants-rutas-codigo";
import {
  convertBase64,
  guardarLocalStorage,
  limpiarLocalStorage,
  obtenerLocalStorage,
  inputNumber,
 
} from "../../resources/funciones";

export const TramitePagos = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const datosComprador = location.state;
  const accion = datosComprador?.accion;
  const vehiculo = datosComprador?.dataVehiculo;
  const estadoTramite = location.state?.estadoTramite;
  const datosPagosLS = obtenerLocalStorage("pagos");
  let fecha = new Date();
  fecha.setDate(fecha.getDate());
  let fechaActual = fecha.toISOString().substring(0, 10);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      TipoPago:
        datosPagosLS?.TipoPago ||
          !datosComprador?.TipoPago
          ? '2'
          : datosComprador?.TipoPago,
      ImporteCredito:
        datosPagosLS?.ImporteCredito ||
          !datosComprador?.ImporteCredito
          ? ""
          : datosComprador?.ImporteCredito,
      ListaMediosPagos:
        datosPagosLS?.ListaMediosPagos ||
          datosComprador?.dataMedioPago?.length === 0 ||
          datosComprador?.dataMedioPago === undefined
          ? [{}]
          : datosComprador?.dataMedioPago,
      ListaCancelaciones:
        datosPagosLS?.ListaCancelaciones ||
          datosComprador?.dataCancelacion?.length === 0 ||
          datosComprador?.dataCancelacion === undefined
          ? [{}]
          : datosComprador?.dataCancelacion,
      Nota: datosPagosLS?.Nota || datosComprador?.nota || "",
    },
  });
  const {
    fields: mediosPagosFields,
    append: mediosPagosAppend,
    remove: mediosPagosRemove,
  } = useFieldArray({ control, name: "ListaMediosPagos" });
  const {
    fields: cancelacionesFields,
    append: cancelacionesAppend,
    remove: cancelacionesRemove,
  } = useFieldArray({ control, name: "ListaCancelaciones" });
  let listaMontoCanc = [];
  const initialValueCanc = 0
  let montoTotalCancelacion = 0
  if (watch('TipoPago') === '2') {
    montoTotalCancelacion = watch("ListaCancelaciones").reduce((suma, obj) => {
      return suma + (Number(obj?.MonCancelacion) || 0)
    }, 0)
  } else {
    montoTotalCancelacion = Number(watch('ImporteCredito'))
  }
  let montoTotalMedPagos = watch("ListaMediosPagos").reduce((suma, obj) => {
    return suma + (Number(obj?.Importe) || 0)
  }, 0)
 
  let listaMontoMedPag = [];
  const initialValueMedPag = 0;
  const [mostrarError, setMostrarError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [isCheck, setIsCheck] = useState(false)
  const [mostrarSpinner, setMostrarSpinner] = useState(false);
 
  
  
  
  
  useEffect(() => {
    if (watch('TipoPago') === '2') {
      setValue('ImporteCredito', "")
    }
  }, [watch('TipoPago')]);
  const onSubmit = async (data) => {
    const montoTotalMedPag = listaMontoMedPag
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialValueMedPag
      )
      .toFixed(2);
    const montoTotalCanc = listaMontoCanc
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialValueCanc
      )
      .toFixed(2);
    if (montoTotalMedPagos !== 0 && montoTotalCancelacion !== 0) {
      if (montoTotalMedPagos === montoTotalCancelacion) {
        let listaPer = [];
        data.Xml = await convertBase64(data?.Xml?.[0]);
        data.Pdf = await convertBase64(data?.Pdf?.[0])
        const medioPago = await Promise.all(await data.ListaMediosPagos.map(async (d) => {
          d.Pdf = await convertBase64(d?.Pdf?.[0])
          d.Xml = await convertBase64(d?.Xml?.[0])
          return {
            MedBolFac: d.Comprobante,
            MedSerie: d.Serie,
            MedNum: d.Numero,
            MedCodPag: d.Comprobante + d.Serie + "-" + d.Numero,
            MonTipPag: d.Moneda,
            ImporPag: parseFloat(d.Importe.replaceAll(",", "")),
            FchBolPag: d.Fecha,
            Xml: d.Xml.archivoBase64 || "",
            Pdf: d.Pdf.archivoBase64 || "",
          };
        })
        )
        const promesasCancelacion = await data.ListaCancelaciones.map(async (d) => {
          
          d.Pdf = await convertBase64(d?.Pdf?.[0])
          return {
            TipMonCanc: d.Moneda,
            TipCambCanc: parseFloat(d?.TipoCambio),
            MonCanc: parseFloat(d.Importe.replaceAll(",", "")),
            FchCanc: d.Fecha,
            CodCanc: d.Codigo,
            OpCanc: d.Operacion,
            BancCanc: d.Banco,
            PdfCan: d.Pdf?.archivoBase64 || "",
          };
        });
        const cancelacion = await Promise.all(promesasCancelacion)
        let comprador = datosComprador.dataCompradores.Compradores.map((d) => {
          
          if (datosComprador.dataCompradores.TipoInmatriculacion === "N") {
            if (d.UnionHecho === "S" || d.SocCony === "S") {
              listaPer.push({
                NomPer: d.ConyNombre,
                ApellPatPer: d.ConyApellidoP,
                ApellMatPer: d.ConyApellidoM,
                RazSocPer: "",
                TipDocPer: d.ConyTipoDoc,
                NumDocPer: d.ConyNumDoc,
                TipContra: "C",
                RucEmpre: "",
                TelefonoPer: d.ConyTelefono,
                EmailPer: d.ConyEmail,
                EstCivPer: d.ConyEstCivil,
                UnionHechPer: d.UnionHecho,
                IdConyugPer: d.TipoDoc + d.NumDoc,
                SocConyugPer: d.SocCony,
                SeparPatrPer: d.SepPatr,
                PartRegPer: d.PartReg || "",
                OficRegPer: d.OficReg || "",
                DocConyugPer: d?.pdf?.archivoBase64 || "",
                DniFrontalPer: {
                  b64: d?.ConyImagenFrontal?.archivoBase64 || "",
                  ext:
                    d?.ConyImagenFrontal?.nombreArchivo?.split(".").pop() || "",
                },
                DniDorsalPer: {
                  b64: d?.ConyImagenDorsal?.archivoBase64 || "",
                  ext:
                    d?.ConyImagenDorsal?.nombreArchivo?.split(".").pop() || "",
                },
                DniSelfiePer: {
                  b64: d?.ConyImagenCliente?.archivoBase64 || "",
                  ext:
                    d?.ConyImagenCliente?.nombreArchivo?.split(".").pop() || "",
                },
                VigenciaPoder: "",
                FichaRuc: "",
                RolPer: "C",
                EstadoPer: "A",
                Direccion: {
                  DomiDir: d.ConyDomicilio,
                  UbigeoDir: d.ConyUbigeo.TUbiCod,
                },
              });
              
            }
            return {
              NomPer: d.Nombre,
              ApellPatPer: d.ApellidoP,
              ApellMatPer: d.ApellidoM,
              TipDocPer: d.TipoDoc,
              NumDocPer: d.NumDoc,
              TipContra: "C",
              RucEmpre: "",
              TelefonoPer: d.Telefono,
              EmailPer: d.Email,
              EstCivPer: d.EstCivil,
              UnionHechPer: d.UnionHecho,
              IdConyugPer:
                d?.ConyNumDoc === "" ? "" : d?.ConyTipoDoc + d?.ConyNumDoc,
              SocConyugPer: d.SocCony,
              SeparPatrPer: d.SepPatr,
              PartRegPer: d.PartReg || "",
              OficRegPer: d.OficReg || "",
              DocConyugPer: d?.pdf?.archivoBase64 || "",
              DniFrontalPer: {
                b64: d?.ImagenFrontal?.archivoBase64 || "",
                ext: d?.ImagenFrontal?.nombreArchivo?.split(".").pop() || "",
              },
              DniDorsalPer: {
                b64: d?.ImagenDorsal?.archivoBase64 || "",
                ext: d?.ImagenDorsal?.nombreArchivo?.split(".").pop() || "",
              },
              DniSelfiePer: {
                b64: d?.ImagenCliente?.archivoBase64 || "",
                ext: d?.ImagenCliente?.nombreArchivo?.split(".").pop() || "",
              },
              VigenciaPoder: "",
              FichaRuc: "",
              RolPer: "C",
              EstadoPer: "A",
              Direccion: {
                DomiDir: d.Domicilio,
                UbigeoDir: d.Ubigeo.TUbiCod,
              },
            };
          } else {
            if (datosComprador.dataCompradores.TipoInmatriculacion === "J") {
              listaPer.push({
                NomPer: d.RepNombre,
                ApellPatPer: d.RepApellidoP,
                ApellMatPer: d.RepApellidoM,
                RazSocPer: "",
                TipDocPer: d.RepTipoDoc,
                NumDocPer: d.RepNumDoc,
                TipContra: "R",
                RucEmpre: d.NumDoc,
                TelefonoPer: d.RepTelefono,
                EmailPer: d.RepEmail,
                EstCivPer: "",
                UnionHechPer: "N",
                IdConyugPer: "",
                SocConyugPer: "N",
                SeparPatrPer: "N",
                PartRegPer: "",
                OficRegPer: "",
                DocConyugPer: "",
                DniFrontalPer: {
                  b64: d?.RepImagenFrontal?.archivoBase64 || "",
                  ext:
                    d?.RepImagenFrontal?.nombreArchivo?.split(".").pop() || "",
                },
                DniDorsalPer: {
                  b64: d?.RepImagenDorsal?.archivoBase64 || "",
                  ext:
                    d?.RepImagenDorsal?.nombreArchivo?.split(".").pop() || "",
                },
                DniSelfiePer: {
                  b64: d?.RepImagenCliente?.archivoBase64 || "",
                  ext:
                    d?.RepImagenCliente?.nombreArchivo?.split(".").pop() || "",
                },
                VigenciaPoder: d?.RepVigPod?.archivoBase64 || "",
                FichaRuc: d?.RepFichaRuc?.archivoBase64 || "",
                RolPer: "C",
                EstadoPer: "A",
                Direccion: {
                  DomiDir: "",
                  UbigeoDir: "",
                },
              });
            }
            return {
              NomPer: "",
              ApellPatPer: "",
              ApellMatPer: "",
              TipDocPer: d.TipoDoc,
              NumDocPer: d.NumDoc,
              RazSocPer: d.RazonSocial,
              TipContra: "C",
              RucEmpre: "",
              TelefonoPer: "",
              EmailPer: "",
              EstCivPer: "",
              UnionHechPer: "",
              IdConyugPer: "",
              SocConyugPer: "",
              SeparPatrPer: "",
              PartRegPer: d.PartReg,
              OficRegPer: d.OficReg,
              DocConyugPer: "",
              DniFrontalPer: {
                b64: "",
                ext: "",
              },
              DniDorsalPer: {
                b64: "",
                ext: "",
              },
              DniSelfiePer: {
                b64: "",
                ext: "",
              },
              VigenciaPoder: "",
              FichaRuc: "",
              RolPer: "C",
              EstadoPer: "A",
              Direccion: {
                DomiDir: d.Domicilio,
                UbigeoDir: d.Ubigeo.TUbiCod,
              },
            };
          }
          
        });
        let personas = comprador.concat(listaPer);
        const token = obtenerLocalStorage("usuario").token;
        const dataPOST = {
          TipoTramit: datosComprador.dataCompradores.TipoInmatriculacion,
          EstTramit: "02",
          DuaTramit: datosComprador.dataCompradores.DUA,
          tokenCompañia: token,
          RepresLegalTramit: datosComprador.dataCompradores.RepLegal,
          Medio_Pago: medioPago,
          Cancelacion: cancelacion,
          ObsTramit: data?.Nota || "",
          Personas: personas,
          XmlPag: data?.Xml.archivoBase64 || "",
          PdfPag: data?.Pdf.archivoBase64 || "",
          CodIofeTramit: "",
        };
        setMostrarSpinner(true);
        const { status, message, num_tramite } = await tramiteCrear(dataPOST);
        
        if (status === 1) {
          navigate(ruta_tramite_confirmacion, { state: { num_tramite } });
          limpiarLocalStorage("tramite");
          limpiarLocalStorage("pagos");
        } else {
          setMostrarSpinner(false);
          setMostrarError(true);
          setMensaje(message);
        }
      } else {
        setMostrarSpinner(false);
        setMostrarError(true);
        setMensaje("Los montos de Medios de Pago y Cancelaciones no coinciden");
      }
    } else {
      console.log(montoTotalMedPagos, montoTotalCancelacion);
      setMostrarSpinner(false);
      setMostrarError(true);
      setMensaje("Los montos de Medios de Pago y Cancelaciones no pueden ser 0.00");
    }
  };
  const regresarTramite = () => {
    mediosPagosFields.forEach((m, index) => {
      setValue(
        `ListaMediosPagos.${index}.Importe`,
        watch(`ListaMediosPagos.${index}.Importe`)
          ?.toString()
          .replaceAll(",", "")
      );
    });
    cancelacionesFields.forEach((c, index) => {
      setValue(
        `ListaCancelaciones.${index}.Importe`,
        watch(`ListaCancelaciones.${index}.Importe`)
          ?.toString()
          .replaceAll(",", "")
      );
    });
    guardarLocalStorage(watch(), "pagos");
    navigate(-1);
  };
  const onSubmitEditar = async (data) => {
    console.log(data);
    if ((montoTotalCancelacion !== 0 || watch('TipoPago') === '1') && montoTotalMedPagos !== 0) {
      if (montoTotalMedPagos === montoTotalCancelacion || isCheck || watch('TipoPago') === '1') {
        data.Xml = await convertBase64(data?.Xml?.[0]);
        data.Pdf = await convertBase64(data?.Pdf?.[0])
        const medioPago = await Promise.all(await data.ListaMediosPagos.map(async (d) => {
          d.Pdf = await convertBase64(d?.Pdf?.[0])
          d.Xml = await convertBase64(d?.Xml?.[0])
          return {
            MedBolFac: d.Comprobante,
            MedSerie: d.Serie,
            MedNum: d.Numero,
            MedCodPag: d.Comprobante + d.Serie + "-" + d.Numero,
            MonTipPag: d.Moneda,
            ImporPag: parseFloat(d.Importe.toString().replaceAll(",", "")),
            FchBolPag: d.Fecha,
            Pdf: d.Pdf.archivoBase64,
            Xml: d.Xml.archivoBase64
            
          };
        })
        )
        const promesasCancelacion = await data.ListaCancelaciones.map(async (d) => {
          d.Pdf = await convertBase64(d?.Pdf?.[0])
          return {
            TipMonCanc: d.Moneda,
            TipCambCanc: parseFloat(d?.TipoCambio),
            MonCanc: parseFloat(d.Importe.toString().replaceAll(",", "")),
            FchCanc: d.Fecha,
            CodCanc: d.Codigo,
            OpCanc: d.Operacion,
            BancCanc: d.Banco,
            PdfCan: d.Pdf?.archivoBase64 || "",
            TotalCan: parseFloat(d.MonCancelacion?.toString().replaceAll(",", ""))
          };
        });
        const cancelacion = await Promise.all(promesasCancelacion)
        const token = obtenerLocalStorage("usuario").token;
        const idTramite = obtenerLocalStorage("tramite").idTramite;
        const dataPOST = {
          IdTramit: idTramite,
          tokenCompañia: token,
          DuaTramit: datosComprador.dataCompradores.DUA,
          Medio_Pago: medioPago,
          Cancelacion: cancelacion,
          ObsTramit: data?.Nota || "",
          TipoPago: watch('TipoPago'),
          ImporteCredito: watch('ImporteCredito'),
         
        };
        setMostrarSpinner(true);
        const { isConfirmed } = await Swal.fire({
          title: estadoTramite == "01" ? "¿Esta seguro?" : "¿Esta seguro de actualizar los datos?",
          html: estadoTramite == "01" ? "<p>En unos minutos el bot generará los documentos. <br/> Una vez generados, deberá validar que los datos de los documentos sean correctos.</p>" : "<p> En unos minutos el bot generará los documentos. <br/> Una vez generados deberá validar que los nuevos datos sean correctos.</p>",
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          showLoaderOnConfirm: true,
          cancelButtonText: "Cancelar",
          allowOutsideClick: false, 
          icon: "info",
          customClass: {
            confirmButton: "btn-lg",
            cancelButton: "btn-lg",
          },
        });
        if (isConfirmed) {
          const { status, message } = await tramiteMedioCancelacionesEditar(
            dataPOST
          );
          if (status === 1) {
            navigate(ruta_historial);
            limpiarLocalStorage("tramite");
            limpiarLocalStorage("pagos");
          } else {
            setMostrarSpinner(false);
            setMostrarError(true);
            setMensaje(message);
          }
        } else {
          setMostrarSpinner(false);
        }
        
      } else {
        setMostrarSpinner(false);
        setMostrarError(true);
        setMensaje("Los montos de Medios de Pago y Cancelaciones no coinciden");
      }
    } else {
      console.log(montoTotalCancelacion);
      setMostrarSpinner(false);
      setMostrarError(true);
      setMensaje(
        "Los montos de Medios de Pago y Cancelaciones no pueden ser 0.00"
      );
    }
  };
  const irVehiculo = () => {
    guardarLocalStorage(watch(), "pagos");
    navigate(ruta_vehiculo, { state: { vehiculo } });
  };
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  return (
    <div>
      <div className="container pt-2">
        <form
          id="createForm"
          className="g-3"
          onSubmit={
            accion === "agregar"
              ? handleSubmit(onSubmit)
              : handleSubmit(onSubmitEditar)
          }
        >
          <div className="pt-3">
            <h5>Datos de Medios de Pago y Cancelaciones</h5>
            <div className="row">
              <div className="col-4">
                <label className="form-label">Forma de Pago</label>
                <select className="form-select"
                  {...register(`TipoPago`, {
                    required: true,
                  })}>
                  <option value="2">Contado</option>
                  {/* <option value="1">Credito</option> */}
                </select>
              </div>
              {watch('TipoPago') === '1' && (<div className="col-4">
                <label className="form-label">Importe Pagado</label>
                <CurrencyInput
                  {...register(`ImporteCredito`, {
                    required: watch('TipoPago'),
                  })}
                  disabled={watch('TipoPago') === '2'}
                  className="form-control"
                  decimalSeparator="."
                  groupSeparator=","
                  decimalsLimit={2}
                  decimalScale={2}
                  onKeyDown={e => inputNumber(e, true)}
                  value={watch(`ImporteCredito`)}
                  onValueChange={(value) => setValue(`ImporteCredito`, value)}
                />
              </div>)}
            </div>
            <div className="row form-section">
              {mediosPagosFields.map((item, index) => {
                return (
                  <div key={item.id}>
                    <TramiteMedioPago
                      index={index}
                      register={register}
                      watch={watch}
                      errors={errors}
                      fecha={fecha}
                      item={item}
                      setValue={setValue}
                      mediosPagosRemove={mediosPagosRemove}
                    />
                  </div>
                );
              })}
              <div className="col-12 mt-3">
                <button
                  className="btn btn-main"
                  onClick={(e) => {
                    e.preventDefault();
                    mediosPagosAppend();
                  }}
                >
                  Agregar Medio de Pago
                </button>
              </div>
              <div
                className="mt-3 text-end pe-5 montos"
                style={{ lineHeight: "25px" }}
              >
                {mediosPagosFields.map((item, index) => {
                  listaMontoMedPag.push(
                    parseFloat(watch(`ListaMediosPagos.${index}.Importe`)) || 0
                  );
                  return (
                    <strong key={index}>
                      Medio de Pago {index + 1}:{" "}
                      {watch(`ListaMediosPagos.${index}.Moneda`) === "PEN"
                        ? "S/."
                        : "$"}
                      {formatValue({
                        value:
                          parseFloat(
                            watch(`ListaMediosPagos.${index}.Importe`)
                          )?.toFixed(2) === "NaN"
                            ? ""
                            : parseFloat(
                              watch(`ListaMediosPagos.${index}.Importe`)
                            )?.toFixed(2),
                        groupSeparator: ",",
                        decimalSeparator: ".",
                      })}
                      <br />
                    </strong>
                  );
                })}
                <p>
                  Monto Total:{" "}
                  {watch(`ListaMediosPagos[0].Moneda`) === "PEN"
                    ? "S/."
                    : "USD $"}
                  {formatValue({
                    value: listaMontoMedPag
                      .reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        initialValueMedPag
                      )
                      .toFixed(2),
                    groupSeparator: ",",
                    decimalSeparator: ".",
                  })}
                </p>
              </div>
              {cancelacionesFields.map((item, index) => {
                return (
                  <div key={item.id}>
                    <TramiteCancelacion
                      index={index}
                      register={register}
                      watch={watch}
                      errors={errors}
                      fecha={fechaActual}
                      item={item}
                      setValue={setValue}
                      
                      reset={reset}
                      cancelacionesRemove={cancelacionesRemove}
                    />
                  </div>
                );
              })}
              {<div className="col-12 mt-3">
                <button
                  className="btn btn-main"
                  onClick={(e) => {
                    e.preventDefault();
                    cancelacionesAppend();
                  }}
                >
                  Agregar Cancelación
                </button>
              </div>}
              <div
                className="mt-3 text-end pe-5 montos"
                style={{ lineHeight: "25px" }}
              >
                {/* {cancelacionesFields?.map((item, index) => {
                  if (
                    watch(`ListaCancelaciones.${index}.Moneda`) !==
                    watch(`ListaMediosPagos[0].Moneda`)
                  ) {
                    let montoCanc;
                    if (watch(`ListaMediosPagos[0].Moneda`) === "USD") {
                      montoCanc = (
                        parseFloat(
                          watch(`ListaCancelaciones.${index}.Importe`)
                            ?.toString()
                            .replaceAll(",", "")
                        ) /
                        parseFloat(
                          watch(`ListaCancelaciones.${index}.TipoCambio`)
                            ?.toString()
                            .substr(0, 5) || 1
                        )
                      ).toFixed(2);
                    } else {
                      montoCanc = (
                        parseFloat(
                          watch(`ListaCancelaciones.${index}.Importe`)
                            ?.toString()
                            .replaceAll(",", "")
                        ) *
                        parseFloat(
                          watch(`ListaCancelaciones.${index}.TipoCambio`)
                            ?.toString()
                            .substr(0, 5) || 1
                        )
                      ).toFixed(2);
                    }
                    listaMontoCanc.push(parseFloat(montoCanc) || 0);
                    return (
                      <strong>
                        Cancelacion {index + 1}:{" "}
                        {watch(`ListaCancelaciones.${index}.Moneda`)}{" "}
                        {formatValue({
                          value: watch(`ListaCancelaciones.${index}.Importe`)
                            ?.toString()
                            .replaceAll(",", ""),
                          groupSeparator: ",",
                          decimalSeparator: ".",
                        })}{" "}
                        (T.C. S/.
                        {watch(
                          `ListaCancelaciones.${index}.TipoCambio`
                        )?.toString() === "NaN"
                          ? ""
                          : watch(`ListaCancelaciones.${index}.TipoCambio`)
                              ?.toString()
                              .substr(0, 4)}
                        ) ={" "}
                        {watch(`ListaMediosPagos[0].Moneda`) === "USD"
                          ? "$"
                          : "S/."}
                        {formatValue({
                          value: montoCanc === "NaN" ? "" : montoCanc,
                          groupSeparator: ",",
                          decimalSeparator: ".",
                        })}
                        <br />
                      </strong>
                    );
                  } else {
                    
                    listaMontoCanc.push(
                      parseFloat(
                        watch(`ListaCancelaciones.${index}.Importe`)
                      ) || 0
                    );
                    
                    return (
                      <strong>
                        Cancelacion {index + 1}:{" "}
                        {watch(`ListaMediosPagos[0].Moneda`) === "USD"
                          ? "$"
                          : "S/."}
                        {formatValue({
                          value:
                            parseFloat(
                              watch(`ListaCancelaciones.${index}.Importe`)
                            )?.toFixed(2) === "NaN"
                              ? ""
                              : parseFloat(
                                  watch(`ListaCancelaciones.${index}.Importe`)
                                )?.toFixed(2),
                          groupSeparator: ",",
                          decimalSeparator: ".",
                        })}
                        <br />
                      </strong>
                    );
                  }
                })} */}
                <p>
                  Monto Total:{" "}
                  {watch(`ListaMediosPagos[0].Moneda`) === "USD" ? "$" : "S/."}
                  {formatValue({
                    value: watch('TipoPago') === '1' ? watch('ImporteCredito') : montoTotalCancelacion.toFixed(2),
                    groupSeparator: ",",
                    decimalSeparator: ".",
                  })}
                </p>
              </div>
              <div className="form-check form-switch d-flex align-items-baseline">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  role="switch"
                  id="switchMontos"
                  onChange={() => setIsCheck(!isCheck)}
                  checked={isCheck} />
                <label className="form-check-label align-top" htmlFor="switchMontos">Deshabilitar Validacion de Montos</label>
              </div>
              <div className="mt-3">
                <h5>Observacion Sunarp</h5>
                <textarea
                  className="form-control"
                  placeholder="Ejm: Las partes indican que el excedente en el deposito de 2500 dolares  ha generado una diferencia de 1000 dolares que será devuelto al comprador, no teniendo nada que reclamar por dicho concepto."
                  style={{ height: "90px" }}
                  
                  
                  
                  
                  {...register("Nota", { required: isCheck, })}
                ></textarea>
                {errors.Nota && errors.Nota.type === 'required' && (
                  <span className="error">Se requiere observación cuando la validacion de monto es deshabilitada</span>
                )}
              </div>
              {mostrarError && (
                <div className="container d-block">
                  <div className="col-8 ">
                        <Grid item xs={6}>
                          <Alert variant="outlined" severity="warning">
                            <p className="fs-6">{mensaje}</p>
                          </Alert>
                        </Grid>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="text-center mx-3">
          {mostrarSpinner ? (
            <div className="spinner-border"></div>
          ) : (
            <div className="col-12 my-3">
              <button
                className="btn btn-secundario mx-3 ps-5 pe-5"
                onClick={regresarTramite}
              >
                Volver
              </button>
              {
               
              }
              {estadoTramite == '01' ? (
                <button
                  className="btn btn-main mx-3 ps-5 pe-5"
                  onClick={handleSubmit(onSubmitEditar)}
                >
                  Registrar
                </button>
              ) : (
                <>
                  {Number(estadoTramite) < 6 &&
                    (<button
                      className="btn btn-main mx-3  ps-5 pe-5"
                      onClick={handleSubmit(onSubmitEditar)}>
                      Actualizar
                    </button>)
                  }
                  {estadoTramite !== '00' && estadoTramite !== '01' && estadoTramite !== '02' && estadoTramite !== '04' &&
                    (<button className="btn btn-main mx-3" onClick={irVehiculo}>
                      Ver Datos del Vehiculo
                    </button>)}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
