

import React, { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import { useLocation, useNavigate } from "react-router-dom";
import {
  ruta_historial,
  ruta_tramite_confirmacion,
  ruta_tramite_pago_agregar,
  ruta_tramite_pago_editar,
} from "../constants/constants-rutas-codigo";

import { DataContext } from "../context/DataContext";
import { actualizarTramite, tramiteCrear } from "../api/tramiteApi";
import { Alert, Grid } from "@mui/material";
import {
  inputNumber,
  convertBase64,
  guardarLocalStorage,
  limpiarLocalStorage,
  obtenerLocalStorage,
} from "../resources/funciones";
import Swal from 'sweetalert2';
import { TramitePersona } from "./inmatriculacion/TramitePersona";
import Tramite from "./inmatriculacion/Tramite";
import { TramitePagos } from "./inmatriculacion/TramitePagos";


export const SinAcceso = () => {
  const imagen = "/images/sin-acceso.png";





  const [activeTab, setActiveTab] = useState(0); // Controla la tab activa

  // Función para renderizar el contenido de la tab activa
  const renderActiveTab = () => {
    switch (activeTab) {
      case 0:
        return <Tramite />;
      case 1:
        return <TramitePagos />;
      case 2:
        return <TramitePagos />;
      default:
        return null;
    }
  };







  return (
    <div className="container-fluid p-3 h-100">  
         


      <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img
            src={imagen}
            alt="imagen"
            className="img-thumbnail"
            style={{ height: "15rem", width: "15rem" }}
          />
          <h3 className="mt-3">
            No está autorizado para navegar en esta página
          </h3>
          <h6>Comuníquese con el área de Sistemas para solicitar acceso</h6>
        </div>
      </div>
    </div>
  );
};




